import React, {lazy} from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./components/context/AuthContext";
import { ThemeProvider } from "@mui/material/styles";

import { ColorModeContext, useMode } from "./theme";
// import { StrictMode } from "react";

const CssBaseline = lazy(() => import("@mui/material/CssBaseline"));
const root = ReactDOM.createRoot(document.getElementById("root"));

function Main() {
  const [theme, colorMode] = useMode(); // Use the custom hook to manage theme

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline /> {/* MUI baseline styles */}
        <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

root.render(
  
    <Main />
 
);
