import React, { useState, useEffect, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Drawer from "@mui/material/Drawer";
import CircularProgress from "@mui/material/CircularProgress";
import { ColorModeContext, useMode } from "./theme";
import PrivateRoute from "./routes/privateroutes";
import { useAuth } from "./components/context/AuthContext";
import { privateRoutes } from "./routes/privateroutes"; // Import private routes
import useInactivityLogout from "./components/hooks/useInactivityLogout";

// Lazy load all route components
const Topbar = React.lazy(() => import("./pages/Global/Topbar"));
const SidebarComponent = React.lazy(() => import("./pages/Global/Sidebar"));
const Dashboard = React.lazy(() => import("./pages/UserPages/Dashboard"));
const Home = React.lazy(() => import("./pages/UserPages/Home/Home"));
const UserManagement = React.lazy(() =>
  import("./pages/AdminPages/UserManagement/usermanagement")
);
const SessionTimeoutDialog = React.lazy(() =>
  import("./components/hooks/SessionTimeoutDialog")
);
const Invoices = React.lazy(() => import("./pages/AdminPages/Invoices"));
const Projections = React.lazy(() => import("./pages/UserPages/Projections"));
const FAQ = React.lazy(() => import("./pages/UserPages/Faq"));
const Calendar = React.lazy(() =>
  import("./pages/UserPages/AdvancedPicks/calendar")
);
const NotFound = React.lazy(() => import("./pages/UserPages/NotFound"));
const Login = React.lazy(() => import("./pages/UserPages/Login"));
const SignupForm = React.lazy(() =>
  import("./pages/UserPages/SignupForm/SignupForm")
);
const AdminDashboard = React.lazy(() =>
  import("./pages/AdminPages/AdminDashboard")
);
const SmartPicks = React.lazy(() => import("./pages/UserPages/SmartPicks"));
const BOTS = React.lazy(() => import("./pages/UserPages/Bots"));
const ModelPerformance = React.lazy(() =>
  import("./pages/UserPages/ModelPerformance")
);
const CreateUser = React.lazy(() => import("./pages/AdminPages/CreateUser"));
const BOTPerformance = React.lazy(() =>
  import("./pages/UserPages/BotPerformance")
);
const Referral = React.lazy(() => import("./pages/UserPages/Referral"));
const Loading = React.lazy(() => import("./pages/UserPages/Loading"));
const Subscriptions = React.lazy(() =>
  import("./pages/PaymentPages/subscriptions")
);
const Cancel = React.lazy(() => import("./pages/PaymentPages/cancel"));
const Success = React.lazy(() => import("./pages/PaymentPages/success"));

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const { currentUser, signupComplete, loading } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { timeoutModalOpen, timeoutCountdown, handleLogout, handleContinue } =
    useInactivityLogout(!!currentUser);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const isPrivateRoute = privateRoutes.includes(location.pathname);

  // Dynamically set the body background color based on theme mode
  useEffect(() => {
    document.body.style.backgroundColor =
      theme.palette.mode === "light" ? "#ffffff" : "#141B32";
  }, [theme.palette.mode]);

  // Render loading screen while AuthContext is initializing
  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<CircularProgress />}>
          <Loading />
        </Suspense>
      </ThemeProvider>
    );
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Suspense fallback={<CircularProgress />}>
            {/* Render Sidebar if on private routes */}
            {currentUser && signupComplete && isPrivateRoute && (
              <>
                {isMobile ? (
                  <Drawer
                    anchor="left"
                    open={isSidebarOpen}
                    onClose={handleSidebarToggle}
                  >
                    <SidebarComponent
                      handleSidebarToggle={handleSidebarToggle}
                      isMobile={isMobile}
                    />
                  </Drawer>
                ) : (
                  <SidebarComponent isSidebarOpen={isSidebarOpen} />
                )}
              </>
            )}

            <main className="content">
              {/* Render Topbar if on private routes */}
              {currentUser && signupComplete && isPrivateRoute && (
                <Topbar
                  onSidebarToggle={handleSidebarToggle}
                  isMobile={isMobile}
                />
              )}

              {/* Session Timeout Dialog */}
              <SessionTimeoutDialog
                countdown={timeoutCountdown}
                open={timeoutModalOpen}
                onLogout={handleLogout}
                onContinue={handleContinue}
              />

              {/* Application Routes */}
              <Routes>
                {/* Public Routes */}
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignupForm />} />
                <Route path="/subscriptions" element={<Subscriptions />} />
                <Route path="*" element={<NotFound />} />

                {/* Admin Protected Routes */}
                <Route
                  path="/usermanagement"
                  element={
                    <PrivateRoute
                      component={UserManagement}
                      requiresAdmin={true}
                    />
                  }
                />
                <Route
                  path="/invoices"
                  element={
                    <PrivateRoute component={Invoices} requiresAdmin={true} />
                  }
                />
                <Route
                  path="/createuser"
                  element={
                    <PrivateRoute component={CreateUser} requiresAdmin={true} />
                  }
                />
                <Route
                  path="/admin/dashboard"
                  element={
                    <PrivateRoute
                      component={AdminDashboard}
                      requiresAdmin={true}
                    />
                  }
                />

                {/* User Protected Routes */}
                <Route
                  path="/dashboard"
                  element={<PrivateRoute component={Dashboard} />}
                />
                <Route
                  path="/cancel"
                  element={<PrivateRoute component={Cancel} />}
                />
                <Route
                  path="/success"
                  element={<PrivateRoute component={Success} />}
                />
                <Route
                  path="/prizepicks/projections"
                  element={<PrivateRoute component={Projections} />}
                />
                <Route
                  path="/prizepicks/advanced"
                  element={<PrivateRoute component={Calendar} />}
                />
                <Route
                  path="/prizepicks/smartpicks"
                  element={<PrivateRoute component={SmartPicks} />}
                />
                <Route
                  path="/prizepicks/bots"
                  element={<PrivateRoute component={BOTS} />}
                />
                <Route
                  path="/model/performance"
                  element={<PrivateRoute component={ModelPerformance} />}
                />
                <Route
                  path="/bot/performance"
                  element={<PrivateRoute component={BOTPerformance} />}
                />
                <Route
                  path="/referral"
                  element={<PrivateRoute component={Referral} />}
                />
                <Route path="/faq" element={<PrivateRoute component={FAQ} />} />
              </Routes>
            </main>
          </Suspense>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
