import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import { getFirebaseAuth } from "../config/firebase"; // Lazy-loaded Firebase auth
import Loading from "../../pages/UserPages/Loading";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [googleData, setGoogleData] = useState(null);
  const [signupComplete, setSignUpComplete] = useState(
    secureLocalStorage.getItem("sc") || false
  );
  const [sub_status, setSubStatus] = useState(
    secureLocalStorage.getItem("ss") || false
  );
  const [sub_type, setSubType] = useState(
    secureLocalStorage.getItem("st") || null
  );
  const [loading, setLoading] = useState(true);
  const [checkingStatus, setCheckingStatus] = useState(false);
  const [role, setRole] = useState(secureLocalStorage.getItem("rl") || null);

  const navigate = useNavigate();

  // Helper function: Clear all authentication-related data
  const clearAuthData = () => {
    setCurrentUser(null);
    setGoogleData(null);
    setSignUpComplete(false);
    setSubStatus(false);
    setSubType(null);
    setRole(null);

    secureLocalStorage.removeItem("sc");
    secureLocalStorage.removeItem("rl");
    secureLocalStorage.removeItem("ss");
    secureLocalStorage.removeItem("st");
    sessionStorage.removeItem("HasCheckedSignup");
  };

  // Helper function: Display errors using react-toastify
  const displayError = (service, error) => {
    const errorMsg = `Sign-in with ${service} failed: ${
      error.message || "Popup closed or request canceled."
    }`;
    toast.error(errorMsg, {
      position: "bottom-center",
      autoClose: 5000,
      transition: Slide,
    });
  };

  // Google Sign-in
  const loginWithGoogle = async () => {
    const auth = await getFirebaseAuth();
    const { GoogleAuthProvider, signInWithPopup } = await import("firebase/auth");
    const googleProvider = new GoogleAuthProvider();

    try {
      await signInWithPopup(auth, googleProvider);
    } catch (error) {
      displayError("Google", error);
    }
  };

  // Apple Sign-in
  const loginWithApple = async () => {
    const auth = await getFirebaseAuth();
    const { OAuthProvider, signInWithPopup } = await import("firebase/auth");
    const appleProvider = new OAuthProvider("apple.com");
    appleProvider.addScope("email");
    appleProvider.addScope("name");

    try {
      await signInWithPopup(auth, appleProvider);
    } catch (error) {
      displayError("Apple", error);
    }
  };

  // Check signup status for authenticated users
  const checkSignupStatus = useCallback(
    async (user, token) => {
      setCheckingStatus(true);

      try {
        const response = await fetch(
          `https://sport-prediction-app-fb7e125de9ad.herokuapp.com/signupcheck`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ uid: user.uid }),
          }
        );

        const data = await response.json();

        if (data.role && data.signupcomplete !== undefined) {
          setRole(data.role);
          setSubStatus(data.sub_status);
          setSubType(data.sub_type);
          setSignUpComplete(data.signupcomplete);

          secureLocalStorage.setItem("rl", data.role);
          secureLocalStorage.setItem("sc", data.signupcomplete);
          secureLocalStorage.setItem("ss", data.sub_status);
          secureLocalStorage.setItem("st", data.sub_type);

          if (!data.signupcomplete) {
            navigate("/signup");
          } else if (!data.sub_status) {
            navigate("/subscriptions");
          } else {
            navigate(data.role === "admin" ? "/admin/dashboard" : "/dashboard");
          }
        } else {
          setSignUpComplete(false);
          navigate("/signup");
        }
      } catch (error) {
        console.error("Error checking signup status:", error);
      } finally {
        setCheckingStatus(false);
        setLoading(false);
      }
    },
    [navigate]
  );

  // Initialize Firebase Auth and listen to auth state changes
  useEffect(() => {
    setLoading(true);

    const initAuth = async () => {
      const auth = await getFirebaseAuth();
      const { onAuthStateChanged } = await import("firebase/auth");

      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          const token = await user.getIdToken();
          setCurrentUser(user);
          setGoogleData({
            fullName: user.displayName,
            email: user.email,
            uid: user.uid,
            photoURL: user.photoURL,
            token: token,
          });

          if (!sessionStorage.getItem("HasCheckedSignup")) {
            await checkSignupStatus(user, token);
          } else {
            setLoading(false);
          }
        } else {
          clearAuthData();
          setLoading(false);
        }
      });

      return () => unsubscribe();
    };

    initAuth();
  }, [checkSignupStatus]);

  // Logout function
  const logout = async () => {
    const auth = await getFirebaseAuth();
    const { signOut } = await import("firebase/auth");

    try {
      await signOut(auth);
      clearAuthData();
      toast.success("Logout successful!", {
        position: "bottom-center",
        autoClose: 2000,
        transition: Slide,
        closeButton: false,
      });
    } catch (error) {
      console.error("Logout Error:", error);
      toast.error("Logout unsuccessful!", {
        position: "bottom-center",
        autoClose: 2000,
        transition: Slide,
        closeButton: false,
      });
    }
  };

  const value = {
    currentUser,
    googleData,
    signupComplete,
    sub_status,
    sub_type,
    loginWithGoogle,
    loginWithApple,
    logout,
    loading,
    checkingStatus,
    role,
    token: googleData?.token,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? <Loading /> : children}
      <ToastContainer />
    </AuthContext.Provider>
  );
};
